<template>

  <div class="d-center-content fill-height" :class="{'mr-7': $vuetify.breakpoint.smAndDown}">

    <div class="d-skew-mother">
      <div class="d-skew-left px-10">Unser aktueller Newsletter</div>
      <div class="d-skew-center"></div>
      <div class="d-skew-right"></div>
      <div class="d-skew-abdecker "></div>
      <div class="d-skew-abdecker-white "></div>
    </div>

      <iframe class="mj-w-res-iframe ml-n4" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://www.unisigns.de/doering/newsletter.html" width="100%" height="5000"></iframe>
  </div>
</template>

<script>


  export default {
    computed: {

    },

    data() {
        return {


        }
      },
      methods: {},
    };
</script>

<style lang="scss">

  .d-cards-hightlight{
    width:280px;
  }
  .d-cards-hightlight-small{
    width:240px;
  }
  .d-cards-hightlight div, .d-cards-hightlight-small div {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}


  // .d-cards-down{

  // }
</style>
